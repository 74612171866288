import request from '@/utils/request'

function getTableList(data){	//日常巡检列表
 return request({
    url: '/api/front/inspection_list',
    method: 'post',
    data: data 
 })
}
function submit(data){	//单独设备提交巡检记录
 return request({
    url: '/api/front/submit_inspection_record',
    method: 'post',
    data: data 
 })
}
function submitAll(data){	//巡检记录--批量提交
   return request({
      url: '/api/front/batch_submit_inspection_record',
      method: 'post',
      data: data ,
   })
  }
export default {
	getTableList,
   submit,
   submitAll
}