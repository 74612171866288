<template>
  <div class="maskLayer" v-if="showSubmitInfoLayer">
    <div class="app-message dialogCon">
      <div class="dcTitle">
        <p class="title">确认信息</p>
        <p class="close" v-on:click="handleCloseLayer">
          <img src="../../assets/images/message/tankuang_delete.png" />
        </p>
      </div>
      <div class="hlCon">
        <!-- 电梯设备信息 -->
        <div class="device-info">
          <div class="bottomCon">
            <div class="item" v-for="(item, index) in detail" :key="index">
              <div class="left-lable">
                {{ item.community_name }}{{ item.device_name }}设备
              </div>
              <div class="right-lable">
                <div class="selectItem">
                  <el-select
                    v-model="item.status"
                    placeholder="请选择设备状态"
                    @change="changeHandle($event, item, index)"
                  >
                    <el-option
                      v-for="el in item.options1"
                      :key="el.value"
                      :label="el.label"
                      :value="el.value"
                    ></el-option>
                  </el-select>
                </div>
                <div class="selectItem" ref="isMesssage" v-show="showMessage">
                  <el-select
                    v-model="item.notify"
                    placeholder="是否通知"
                    @change="changeStatusHandle($event, item, index)"
                  >
                    <el-option
                      v-for="el in item.options2"
                      :key="el.value"
                      :label="el.label"
                      :value="el.value"
                    ></el-option>
                  </el-select>
                </div>
                <div class="selectItem" ref="msgStatus" v-show="showMsgStatus">
                  <el-select
                    v-model="item.notify_type"
                    placeholder="请选择通知状态"
                  >
                    <el-option
                      v-for="el in item.options3"
                      :key="el.value"
                      :label="el.label"
                      :value="el.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="left-lable remark">备注 :</div>
              <div class="right-lable textarea">
                <textarea
                  v-model="parmasInfo.remark"
                  type="text"
                  placeholder="请输入备注"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dcBottom">
        <p class="item" v-on:click="handleHintInfo">确定</p>
        <p class="item" v-on:click="handleCloseLayer">取消</p>
      </div>
    </div>
  </div>
</template> 
<script>
import checkListHttps from "@/https/checkList/index";
export default {
  props: {
    showSubmitInfoLayer: {
      type: Boolean,
      default: false,
    },
    emergencyList: {
      type: Array,
      default: () => [],
    },
  },
  name: "",
  data() {
    return {
      showMessage: false,
      showMsgStatus: false,
      statusObj: {
        changeVal: {},
        changeStatusVal: {},
      },

      newItem: {},
      parmasInfo: {
        detail: [],
        remark: "",
      },
      detail: [],
      options1: [
        {
          value: "0",
          label: "异常",
        },
        {
          value: "1",
          label: "正常",
        },
      ],
      options2: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      options3: [
        {
          value: "1",
          label: "APP通知",
        },
        {
          value: "2",
          label: "电话通知",
        },
        {
          value: "3",
          label: "公网对讲",
        },
        {
          value: "4",
          label: "运维派单",
        },
      ],
    };
  },
  created() {
    //全部告警
    this.detail = JSON.parse(JSON.stringify(this.emergencyList));
    this.detail.forEach((item) => {
      this.$set(item, "status", "");
      this.$set(item, "notify", "");
      this.$set(item, "notify_type", "");
      this.$set(item, "options1", this.options1);
      this.$set(item, "options2", this.options2);
      this.$set(item, "options3", this.options3);
      // item.status = item.options1[0].value;
      // item.notify = item.options2[0].value;
      // item.notify_type = item.options3[0].value;
    });
  },
  watch: {
    statusObj: {
      handler(val) {
        if (val.changeVal.value == 0) {
          this.$refs.isMesssage[val.changeVal.index].style.display = "block";
          this.$refs.msgStatus[val.changeVal.index].style.display = "none";
          if (val.changeStatusVal.value == 1) {
            this.$refs.msgStatus[val.changeVal.index].style.display = "block";
          } else if (val.changeStatusVal.value == 0) {
            this.$refs.msgStatus[val.changeVal.index].style.display = "none";
          }
        } else if (val.changeVal.value == 1) {
          this.$refs.isMesssage[val.changeVal.index].style.display = "none";
          this.$refs.msgStatus[val.changeVal.index].style.display = "none";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    // 提交全部
    async handleHintInfo() {
      let that = this;
      let parmas = [];
      this.detail.map((item) => {
        // parmas.map((el, index2, arr2) => {
        //   arr2[index2].id = arr1[index1].id;
        //   arr2[index2].status = arr1[index1].status;
        //   arr2[index2].notify = arr1[index1].notify;
        //   arr2[index2].notify_type = arr1[index1].notify_type;
        // });
        // return parmas;
        parmas.push(JSON.parse(JSON.stringify(item)));
        parmas.forEach((el) => {
          delete el.address;
          delete el.alarm_event;
          delete el.alarm_level;
          delete el.alarm_level_name;
          delete el.app;
          delete el.community_name;
          delete el.create_time;
          delete el.deal_time;
          delete el.deal_user;
          delete el.device_id;
          delete el.device_name;
          delete el.event_level_no;
          delete el.false_alarm;
          delete el.is_deal;
          delete el.is_notify;
          delete el.is_read;
          delete el.options1;
          delete el.options2;
          delete el.options3;
          delete el.pics;
          delete el.stream;
          delete el.stream_url;
          delete el.ts_ct;
          delete el.update_time;
          delete el.user_no;
          delete el.username;
          delete el.remark;
          delete el.alarm_time;
          delete el.community_id;
        });
      });
      // console.log(parmas, 8811);
      // that.parmasInfo = {
      //   detail: JSON.stringify(parmas),
      // };
      (that.parmasInfo.detail = JSON.stringify(parmas)),
        checkListHttps.submitAll(that.parmasInfo).then(function (res) {
          if (res.data.code == 200) {
            that.$emit("getTableData");
            that.$emit("update:showSubmitInfoLayer", false);
          } else {
            that.$message.error("提交失败");
          }
        });
    },
    changeHandle(val, item, index) {
      // console.log(val, item, index);
      this.$set(this.statusObj.changeVal, "value", val);
      this.$set(this.statusObj.changeVal, "item", item);
      this.$set(this.statusObj.changeVal, "index", index);
    },
    changeStatusHandle(val, item, index) {
      this.$set(this.statusObj.changeStatusVal, "value", val);
      this.$set(this.statusObj.changeStatusVal, "item", item);
      this.$set(this.statusObj.changeStatusVal, "index", index);
    },
    // selectItem(item) {
    //   console.log(item, 990);
    // },
    handleCloseLayer() {
      this.$emit("update:showSubmitInfoLayer", false);
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/submit/index.scss";
</style>
<style scoped>
.el-input__inner {
  height: 100px;
}
.maskLayer .dialogCon .hlCon .device-info .bottomCon .item .textarea {
  width: 500px;
}
</style>
