<template>
  <div class="container">
    <div class="topTitle">
      <div class="leftCon">日常巡检列表</div>
      <div class="bodyCon">
        <div class="item">
          <span>本月任务：</span>
          <p class="all-task">24825</p>
          <div class="img">
            <img src="@/assets/images/checkList/xian_red.png" alt="" />
          </div>
        </div>
        <div class="item">
          <span>已完成任务：</span>
          <p class="finish">13258</p>
          <div class="img">
            <img src="@/assets/images/checkList/xian_green.png" alt="" />
          </div>
        </div>
        <div class="item">
          <span>未完成任务：</span>
          <p class="no-finish">11625</p>
          <div class="img">
            <img src="@/assets/images/checkList/xian_yellow.png" alt="" />
          </div>
        </div>
        <div class="item">
          <span>月度任务工时：</span>
          <p class="all-task">300:00:00</p>
          <div class="img">
            <img src="@/assets/images/checkList/xian_red.png" alt="" />
          </div>
        </div>
        <div class="item">
          <span>已完成工时：</span>
          <p class="finish">200:00:00</p>
          <div class="img">
            <img src="@/assets/images/checkList/xian_green.png" alt="" />
          </div>
        </div>
        <div class="item">
          <span>未完成工时：</span>
          <p class="no-finish">100:00:00</p>
          <div class="img">
            <img src="@/assets/images/checkList/xian_yellow.png" alt="" />
          </div>
        </div>
      </div>
      <div class="rightCon">
        <img src="@/assets/images/index/button_quanping.png" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="conLeft">
        <div class="videoList">
          <!-- 严重警告 -->
          <div
            class="serious-warning father-box"
            v-for="item in inspectionList"
            :key="item.id"
          >
            <!-- <VideoList :videoUrl="videoUrl"></VideoList> -->
            <div
              class="top-title"
              :style="{
                background:
                  item.alarm_level == 0
                    ? '#127E78'
                    : item.alarm_level == 1
                    ? '#A84C4D'
                    : item.alarm_level == 2
                    ? '#A9641D'
                    : '#A99647',
              }"
            >
              <div class="top-title-left">
                <img src="@/assets/images/checkList/shequ_img.png" alt="" />
                <span>{{ item.community_name }}</span>
              </div>
              <div class="top-title-right">
                <span>{{ item.alarm_time }}</span>
              </div>
            </div>
            <div class="warn-content">
              <div class="leftCon">
                <div class="img">
                  <img src="@/assets/images/checkList/dianti_img.png" alt="" />
                </div>
              </div>
              <div class="rightCon">
                <div class="titleCon">
                  {{ item.community_name }}{{ item.device_name }}设备
                </div>
                <div class="address">{{ item.address }}</div>
                <p class="event">
                  告警事件：<span>{{ item.alarm_event }}</span>
                </p>
                <p>
                  告警时间：<span>{{ item.alarm_time }}</span>
                </p>
                <p v-if="item.alarm_level == 0" class="level">
                  告警等级：<span>
                    <img
                      src="@/assets/images/checkList/alarm_zhengchang.png"
                      alt=""
                    />
                    <span style="color: #49ce39">正常</span>
                  </span>
                </p>
                <p v-else-if="item.alarm_level == 1" class="level">
                  告警等级：<span>
                    <img
                      src="@/assets/images/checkList/alarm_yanzhong.png"
                      alt=""
                    />
                    <span style="color: #f7517f">严重告警</span>
                  </span>
                </p>
                <p v-else-if="item.alarm_level == 2" class="level">
                  告警等级：<span>
                    <img
                      src="@/assets/images/checkList/alarm_ciji.png"
                      alt=""
                    />
                    <span style="color: #fe9239">次级告警</span>
                  </span>
                </p>
                <p v-else-if="item.alarm_level == 3" class="level">
                  告警等级：<span>
                    <img
                      src="@/assets/images/checkList/alarm_yiban.png"
                      alt=""
                    />
                    <span style="color: #feeb1a">一般告警</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="conRight">
        <div class="task">
          <div class="top-title">
            <p class="txt">任务列表</p>
            <div class="line"></div>
          </div>
          <div class="content_info">
            <div
              class="content_info_list item"
              v-for="(item, index) in inspectionList"
              :key="index"
              @click="checkItemHandle(item, index)"
            >
              <div class="task-info-left">
               <span> {{ index + 1 }}、{{ item.community_name
                }}{{ item.device_name }}设备 #1234548798</span>
              </div>
              <div :class="activeIndex == index ? 'active' : ''"></div>
              <div class="task-info-right">
                <img
                  v-if="item.alarm_level == 0"
                  src="@/assets/images/index/alarm_zhengchang.png"
                  alt=""
                />
                <img
                  v-else-if="item.alarm_level == 1"
                  src="@/assets/images/index/alarm_yanzhong.png"
                  alt=""
                />
                <img
                  v-else-if="item.alarm_level == 2"
                  src="@/assets/images/index/alarm_ciji.png"
                  alt=""
                />
                <img
                  v-else-if="item.alarm_level == 3"
                  src="@/assets/images/index/alarm_yiban.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="details" >
          <div class="top-title">
            <p class="txt">任务统计</p>
            <div class="line"></div>
          </div>
          <div class="content_info">
            <div class="content_info_list">
              <div class="title">
                <div><span>今日 任务</span></div>
                <div><span>已完成 任务</span></div>
                <div><span>未完成 任务</span></div>
              </div>
              <div class="content">
                <ul>
                  <li class="li1">
                    <div>50组</div>
                    <div>02:00:00</div>
                    <div>
                      <p>50组</p>
                      <p>02:00:00</p>
                    </div>
                  </li>
                  <li class="li2">
                    <div>12组</div>
                    <div>00:52:00</div>
                    <div>
                      <p>12组</p>
                      <p>00:52:00</p>
                    </div>
                  </li>
                  <li class="li3">
                    <div>38组</div>
                    <div>01:08:00</div>
                    <div>
                      <p>38组</p>
                      <p>01:08:00</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="handle" v-show="flag">
          <div class="top-title">
            <p class="txt">处理方式</p>
            <div class="line"></div>
          </div>
          <div class="content_info">
            <button class="app" @click="messageHandle(1)">APP通知</button>
            <button @click="messageHandle(2)">电话通知</button>
            <button @click="messageHandle(3)">公网处理</button>
            <button @click="messageHandle(4)">运维派单</button>
          </div>
        </div>
        <div class="submit" v-show="flag">
          <button @click="submit">提交</button>
        </div>
      </div>
    </div>
    <!-- 消息弹窗 -->
    <appMessageLayer
      :showAppMessageLayer.sync="showAppMessageLayer"
      :checkItem="checkItem"
      :subType="subType"
      @getTableData="publicGetTableData"
    ></appMessageLayer>
    <phoneMessageLayer
      :showPhoneMessageLayer.sync="showPhoneMessageLayer"
    ></phoneMessageLayer>
    <publicSpeakerLayer
      :showPublicSpeakerLayer.sync="showPublicSpeakerLayer"
    ></publicSpeakerLayer>
    <submitInfoLayer
      v-if="showSubmitInfoLayer"
      :showSubmitInfoLayer.sync="showSubmitInfoLayer"
      :emergencyList="inspectionList"
      @getTableData="publicGetTableData"
    ></submitInfoLayer>
  </div>
</template>
<script>
import appMessageLayer from "../../components/CheckMessageLayer/appMessageLayer.vue";
import phoneMessageLayer from "../../components/CheckMessageLayer/phoneMessageLayer.vue";
import publicSpeakerLayer from "../../components/CheckMessageLayer/publicSpeakerLayer.vue";
import submitInfoLayer from "../../components/submit/submitInfoLaye1.vue";

// import VideoList from "@/components/videoList/videoList.vue";

import checkListHttps from "../../https/checkList/index";
// import format from "../../utils/utils";
export default {
  name: "",
  components: {
    appMessageLayer,
    phoneMessageLayer,
    publicSpeakerLayer,
    submitInfoLayer,
  },
  data() {
    return {
      flag: false,
      color: "",
      videoUrl: "http://192.168.1.220:812/3.mp4",
      showAppMessageLayer: false,
      showPhoneMessageLayer: false,
      showPublicSpeakerLayer: false,
      showSubmitInfoLayer: false,
      formInline: {
        offset: 1,
        limit: 10,
      },
      activeIndex: null,
      inspectionList: [],
      checkItem: {},
      subType: "",
    };
  },
  created() {
    // let time = format.dateFormat(new Date(), "yyyy-M-d hh:mm:ss");
    // console.log(time);
    this.getVideoList();
    // console.log(this.$store.state.state.userInfo.id);
  },
  mounted() {},
  computed: {},
  methods: {
    //获取列表
    getVideoList() {
      let that = this;
      checkListHttps.getTableList(that.formInline).then(function (res) {
        if (res.data.code == 200) {
          that.inspectionList = res.data.data;
        } else {
          that.$message({
            showClose: true,
            message: "暂无数据",
            type: "error",
          });
        }
      });
    },
    publicGetTableData() {
      this.getVideoList();
    },
    //选择设备
    checkItemHandle(item, index) {
      this.activeIndex = index;
      this.isActive = true;
      this.checkItem = item;
      this.flag = true;
    },
    messageHandle(val) {
      // console.log(this.checkItem);
      if (this.checkItem.id) {
        if (val == 1) {
          this.subType = toString(val);
          this.showAppMessageLayer = true;
        } else if (val == 2) {
          this.subType = toString(val);
          this.showPhoneMessageLayer = true;
        } else if (val == 3) {
          this.subType = toString(val);
          this.showPublicSpeakerLayer = true;
        } else {
          this.subType = toString(val);
          console.log("运维派单");
        }
      } else {
        this.$message.warning("请先选择设备");
      }
    },
    submit() {
      this.showSubmitInfoLayer = true;
    },
  },
};
</script>

<style lang='scss'  scoped>
@import "@/scss/checkList/index.scss";
</style>
