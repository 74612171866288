<template>
  <div class="maskLayer" v-if="showPhoneMessageLayer">
    <div class="app-message dialogCon">
      <div class="dcTitle">
        <p class="title">电话通知</p>
        <p class="close" v-on:click="handleCloseLayer">
          <img src="../../assets/images/message/tankuang_delete.png" />
        </p>
      </div>
      <div class="hlCon">
        <!-- 设备信息 -->
        <div class="device-info">
          <div class="topTitle">
            <div class="line"></div>
            <div class="txt">设备信息</div>
          </div>
          <div class="bottomCon">
            <div class="item">
              <div class="left-lable">社区名称 :</div>
              <div class="right-lable">幸福佳苑小区</div>
            </div>
            <div class="item">
              <div class="left-lable">设备名称 :</div>
              <div class="right-lable">幸福佳苑电梯设备</div>
            </div>
            <div class="item">
              <div class="left-lable">设备位置 :</div>
              <div class="right-lable">1栋1单元1号梯</div>
            </div>
            <div class="item">
              <div class="left-lable">告警事件 :</div>
              <div class="right-lable">无</div>
            </div>
            <div class="item">
              <div class="left-lable">告警等级 :</div>
              <div class="right-lable">
                <img src="@/assets/images/index/alarm_yanzhong.png" alt="" />
                严重告警
              </div>
            </div>
            <div class="item">
              <div class="left-lable">巡检开始时间：</div>
              <div class="right-lable">2022-06-12 12:00:00</div>
            </div>
            <div class="item">
              <div class="left-lable">当前时间：</div>
              <div class="right-lable">2022-06-12 12:00:05</div>
            </div>
            <div class="item">
              <div class="left-lable">值班人员 :</div>
              <div class="right-lable">2号哨兵 张志平</div>
            </div>
            <div class="item">
              <div class="left-lable">负责人：</div>
              <div class="right-lable">李东</div>
            </div>
            <div class="item">
              <div class="left-lable">负责人电话：</div>
              <div class="right-lable phone">
                <a href="tel: 1688888888">
                  <img src="@/assets/images/message/button_boda.png" alt="" />
                  <span>隐藏号码</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dcBottom">
        <p class="item" v-on:click="handleHintInfo">确定</p>
        <p class="item" v-on:click="handleCloseLayer">取消</p>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  props: {
    showPhoneMessageLayer: {
      type: Boolean,
      default: false,
    },
  },
  name: "",
  data() {
    return {
      radio: "1",
    };
  },
  created() {},
  computed: {},
  methods: {
    handleHintInfo() {},
    handleCloseLayer() {
      this.$emit("update:showPhoneMessageLayer", false);
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/CheckMessageLayer/phoneMessageLayer.scss";
</style>
